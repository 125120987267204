.brokerage-card {
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  width: 200px;
  height: 125px;
  justify-content: center;
  display: flex;
  transform: scale(1);
  transition: transform 0.2s ease-in-out;
}
.brokerage-card:hover {
  transform: scale(1.05);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
}

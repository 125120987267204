.center-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.auth-card {
  background-color: white;
  width: 400px;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.auth-card-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 24px;
  text-align: center;
}

.auth-card-create-account {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

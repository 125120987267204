header.ant-layout-header.unauth-header {
  background: white;
  border-bottom: 1px solid #000;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 1);
}

header.ant-layout-header.auth-header {
  background: white;
  border-bottom: 1px solid #000;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 1);
}

.checkbox-group {
  display: flex;
  flex-direction: column;
}
.checkbox-group .ant-checkbox-group-item {
  margin-left: 0;
  margin-bottom: 10px;
}
.modalHeading {
  margin-top: 10px;
  margin-bottom: 10px;
}

body {
  margin: 0;
  font-family: Satoshi;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
}

@font-face {
  font-family: Satoshi;
  font-weight: 400 800;
  src: url(./assets/fonts/Satoshi-Variable.ttf);
}

h1 {
  font-size: 2rem;
  font-weight: 600;
  margin: 0;
  margin-bottom: 8px;
}

h2 {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
}

h3 {
  font-size: 1.25rem;
  font-weight: 400;
  margin: 0;
}

h4 {
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
}

h5 {
  font-size: 1rem;
  font-weight: 300;
  margin: 0;
}

h6 {
  font-size: 0.875rem;
  font-weight: 300;
  margin: 0;
}

.ant-menu-item-selected {
  background-color: white !important;
  box-shadow: 0 0 17.5px rgba(0, 0, 0, 0.25);
}

.ant-menu-item-selected svg {
  color: #9e9bc3;
}
